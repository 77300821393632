@font-face {
    font-family: 'Officina Serif ITC TT';
    src: url('./OfficinaSerifITCTTBook.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Officina Serif ITC TT';
    src: url('./OfficinaSerifITCTTBold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

