

/*------------------------------------------*/
/*  LANDING
/*------------------------------------------*/

/* Header Menu */
header {box-shadow: none;}
div.main-header-desktop__menu.landing {justify-content: center;}
header.on .main-header-desktop__menu.landing { transform: translateY(0px);}
header div.main-header-desktop__menu.landing .main-header-desktop__menu__top {border: none;}

/* Banner */
.vivo-landing-2024__section { padding: 100px 0 100px; background-color: #f7f7f7;  }
.vivo-landing-2024__banner { padding: 50px; border-radius: 15px; position: relative; overflow: hidden; background-color: black; background-position: center; background-size: cover; background-repeat: no-repeat; background-image: url(../img/landing-2024/vivo-landing-2024-banner-bg.jpg); }
.vivo-landing-2024__banner__content { color: white; max-width: 750px; }
.vivo-landing-2024__banner__content > img { max-width: 60px; }
.vivo-landing-2024__banner__content > h2 {color: white;    font-family: var(--vivo-light); font-size: 40px; margin-bottom: 20px;}
.vivo-landing-2024__banner__content > p { font-size: 16px; }
.vivo-landing-2024__banner__content > .vivo-landing-2024__timmer { display: grid; grid-template-columns: auto 1fr; align-items: center; gap: 10px; margin: 30px 0 0;  }
.vivo-landing-2024__banner__content > .vivo-landing-2024__timmer img { width: 35px;}
.vivo-landing-2024__banner__content > .vivo-landing-2024__timmer p {font-size: 16px;}


/* FOOTER */
#footer { background-color: var(--fondo-vivo); }
#footer .vivo-info { padding: 50px 0 50px; }
#footer .vivo-info > .container { color: white; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; justify-content: space-between; gap: 20px; font-family: var(--roboto); font-size: 18px; font-weight: 300; }
#footer .vivo-info_col h2 { color: white; font-family: var(--roboto); font-weight: normal; font-size: 20px; margin-bottom: 10px; }
#footer .vivo-info_col a { display: block; margin-bottom: 5px; }
#footer .vivo-info_col p.small { font-size: 14px; font-weight: bold; margin-top: 15px; }

#footer .vivo-legales {font-family: var(--roboto); color: white; padding-bottom: 30px;}
#footer .vivo-legales p { font-size: 14px; line-height: 1.3; }
#footer .vivo-legales p.big {font-size: 18px; font-weight: bold; text-align: center; }

#footer .vivo-logos { padding: 20px 0; margin-bottom: 0; background-color: white;}
#footer .vivo-logos .vivo-logos__lista { width: 100%; display: grid; grid-template-columns: auto auto auto; align-items: center; justify-content: space-between; gap: 20px; padding: 20px 0; }
#footer .vivo-logos .vivo-logos__lista img { width: 100%; max-height: 35px; object-fit: contain; }


/*-------------------------------------------------------------------------------------------------------------------------------------------------*/
/*  RESPONSIVE
/*-------------------------------------------------------------------------------------------------------------------------------------------------*/

/* IPAD */


@media only screen and (min-width: 768px) and (max-width: 1024px) {


    /*------------------------------------------*/
    /*  LANDING
    /*------------------------------------------*/
    header .main-header-desktop__logo img.landing, header.on .main-header-desktop__logo img.landing { width: 85px; max-width: 85px; }
    div.main-header-desktop__menu.landing { justify-content: flex-end; }

    .vivo-landing-2024__banner { background-blend-mode: multiply; background-color: rgba(0, 0, 0, 0.5); background-position: 80% center; }
    #footer .vivo-info > .container {grid-template-columns: repeat(2,1fr); gap: 60px 40px;}
    #footer .vivo-legales p.big {text-align: left;}

}

/* FIN IPAD */

/* MOBILE */
@media screen and (max-width: 767px) {



div.main-header-desktop__menu.landing {justify-content: flex-end;}
header .main-header-desktop__logo img.landing, header.on .main-header-desktop__logo img.landing { width: 85px; max-width: 85px; }

.vivo-landing-2024__section {padding: 20px 0;}
.vivo-landing-2024__banner {padding: 30px; background-blend-mode: multiply; background-color: rgba(0, 0, 0, 0.7); background-position: 80% center;}
.vivo-landing-2024__banner__content > img {width: 50px;}
.vivo-landing-2024__banner__content > h2 {font-size: 27px;}

#footer .vivo-info > .container {grid-template-columns: repeat(1,1fr); gap: 40px;}
#footer .vivo-legales p.big {text-align: left;}
#footer .vivo-logos .vivo-logos__lista {grid-template-columns: 1fr; gap: 30px;}


} 

