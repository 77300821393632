.u_dialog_container {
    /* flex-column */
    display: flex;
    flex-direction: row;
    /* others */
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.u-dialog-hidden {
    display: none;
}

.u-dialog {
    position: relative;
    z-index: 10000;
}

.u-dialog-close {
    position: absolute;
    top: 1.1rem;
    right: 1.1rem;
}

.App {
    display: block !important;
}
